.fourcard {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;

  color: var(--body_color);

}

.bio-text{
  color: var(--body_color);
  margin-left: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  
}

.headerr {
  text-align: center;
  margin-bottom: 30px;
  color: var(--clr-light);
}

.img-img{
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 98vw;
  height: 50vh;
}

.special {
  color: var(--body_color);

}

.description {
  color: var(--body_color);
  margin-left: 2rem;

}




.supervisor,
.teambuilder,
.karma,
.calculator {
  width: 47vw;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 24px;
  margin-bottom: 30px;
  background-color: #ffffff;
  padding: 0px;
margin: 5px;
  background: var(--body_background);
}

.imgicon {
  text-align: center;
}

@media screen and (max-width: 768px) {

  .supervisor,
  .teambuilder,
  .karma,
  .calculator {
    width: 100%;
  }
}