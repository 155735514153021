
  
  .form-submitter {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 33rem;
    width: 100%;
    max-width: 400px; /* Limit the width of the form */
  }
  .admin-dashboard{
    text-align: center;
    margin-top: 3rem;
  }
  
  .form-submitter input[type="file"] {
    margin-bottom: 10px;
    width: 100%; /* Ensure input takes the full width of the form */
  }
  
  .form-submitter button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
   
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%; /* Ensure button takes the full width of the form */
  }
  
  .form-submitter button:hover {
    background-color: #45a049;
  }
  
  
 
  
  .image-grid button:hover {
    background-color: #d32f2f;
  }
  
  /* Responsive styles */
  @media (max-width: 992px) {
    .image-grid {
      width: calc(50% - 20px); /* Two items per row */
    }
    
  
  .form-submitter {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 6rem;
    width: 100%;
    max-width: 400px; /* Limit the width of the form */
  }
  .form-submitter button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
   
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 12rem;
    width: 50%; /* Ensure button takes the full width of the form */
  }
  }
  
  @media (max-width: 576px) {
    .image-grid {
      width: calc(100% - 20px); /* One item per row */
    }
  
    form {
      width: 100%;
    }
  
    form button {
      width: 100%;
    }
  }
  