@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
    --clr-bg-header: #ffffff;
    --clr-btn: #ffffff;
    --clr-dropdown: #ffffff;
    --clr-nav-hover: #ffffff;
    --clr-dropdown-hov: #ffffff;
    --clr-dropdown-link-hov: #ffffff;
    --clr-light: #000000;
    --body_background: #ffffff;
    --body_color: #000000;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

/* Add this class to hide the nav menu */
.nav-hidden {
    transform: translateX(100%);
    transition: .65s;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
    position: relative; /* Required for underline animation */
}

a::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: var(--clr-light);
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
}

a:hover::after {
    visibility: visible;
    transform: scaleX(1);
}

.logo-rca{
    width: 6vw;
    height: auto;
   margin-top: -1rem;
   cursor: pointer;
   margin-left: -9vw;
   box-shadow: 3px 5px 10px 3px solid black;
   
    
}
.header{
    height: auto;
}
.togger {
    position: sticky;
    top: 0;
    border-bottom: 1px solid black;
    width: 100%;
    height: 10vh;
    z-index: 1000;
    backdrop-filter: blur(40px);
    background: var(--body_background);
    animation: slide-down 0.5s ease;
}

section {
    position: relative;
    height: calc(100vh - 3rem);
    width: 100%;
    overflow: hidden;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.container {
    max-width: 65rem;
    padding: 0 2rem;
    margin: 0 auto;
    display: flex;
}

.logo-container {
    display: flex;
    margin-left: auto;
    
}


.imagelogo {
    width: 5vw;
    height: auto;
    animation: fade-in 1s ease;
}

.nav-btn {
    flex: 3;
    display: flex;
}

.nav-links {
    flex: 2;
}

.log-sign {
    display: flex;
    margin-left: auto;
    align-items: center;
    flex: 1;
}

#moon {
    margin-left: 4rem;
    color: var(--body_color);
    cursor: pointer;
}

.logo {
    color: var(--clr-light);
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 3rem;
    cursor: pointer;
    margin-top: auto;
    margin-left: -3rem;
    color: var(--body_color);
   
}

.logo span {
    font-weight: 300;
}

.btn {
    display: inline-block;
    padding: .5rem 1.3rem;
    font-size: .8rem;
    border: 2px solid var(--clr-light);
    border-radius: 2rem;
    line-height: 1;
    margin: 0 .2rem;
    transition: .3s;
    text-transform: uppercase;
    animation: fade-in 0.5s ease;
}

.btn.solid,
.btn.transparent:hover {
    background-color: var(--clr-light);
    color: var(--clr-btn);
}

.btn.transparent,
.btn.solid:hover {
    background-color: transparent;
    color: var(--clr-light);
}

.nav-links > ul {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-link {
    position: relative;
}

.nav-link > a {
    line-height: 3rem;
    color: var(--clr-light);
    color: var(--body_color);
    padding: 0 .8rem;
    letter-spacing: 1px;
    font-size: .95rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: .5s;
    animation: fade-in 0.5s ease;
}

.nav-link > a > i {
    margin-left: .2rem;
}

.nav-link:hover > a {
    transform: scale(1.1);
}

.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 10rem;
    transform: translateY(10px);
    opacity: 0;
    pointer-events: none;
    transition: .5s;
    animation: fade-in-up 0.5s ease;
}

.dropdown ul {
    position: relative;
}

.dropdown-link > a {
    display: flex;
    background-color: var(--clr-light);
    color: var(--clr-dropdown);
    padding: .5rem 1rem;
    font-size: .9rem;
    align-items: center;
    justify-content: space-between;
    transition: .3s;
    animation: fade-in-up 0.5s ease;
}

.dropdown-link:hover > a {
    background-color: var(--clr-dropdown);
    color: var(--clr-light);
}

.dropdown-link:not(:nth-last-child(2)) {
    border-bottom: 1px solid var(--clr-light);
}

.dropdown-link i {
    transform: rotate(-90deg);
}

.arrow {
    position: absolute;
    width: 11px;
    height: 11px;
    top: -5.5px;
    left: 32px;
    background-color: var(--clr-light);
    transform: rotate(45deg);
    cursor: pointer;
    transition: .3s;
    z-index: -1;
    color: var(--body_color);
}

.dropdown-link:first-child:hover ~ .arrow {
    background-color: var(--clr-dropdown);
}

.dropdown-link {
    position: relative;
}

.dropdown.second {
    top: 0;
    left: 100%;
    padding-left: .8rem;
    cursor: pointer;
    transform: translateX(10px);
}

.dropdown.second .arrow {
    top: 10px;
    left: -5.5px;
}

.nav-link:hover > .dropdown,
.dropdown-link:hover > .dropdown {
    transform: translate(0, 0);
    opacity: 1;
    pointer-events: auto;
}

.hamburger-menu-container {
    flex: 1;
    display: none;
    align-items: center;
    justify-content: flex-end;
}

.hamburger-menu {
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: auto;
    animation: fade-in 0.5s ease;
}

.hamburger-menu div {
    width: 1.6rem;
    height: 3px;
    border-radius: 3px;
    background-color: var(--clr-light);
    position: relative;
    z-index: 1001;
    transition: .5s;
}

.hamburger-menu div:before,
.hamburger-menu div:after {
    content: '';
    position: absolute;
    width: inherit;
    height: inherit;
    background-color: var(--clr-light);
    border-radius: 3px;
    transition: .5s;
}

.hamburger-menu div:before {
    transform: translateY(-7px);
}

.hamburger-menu div:after {
    transform: translateY(7px);
}

#check {
    position: absolute;
    top: 50%;
    right: 1.5rem;
    transform: translateY(-50%);
    width: 2.5rem;
    height: 2.5rem;
    z-index: 90000;
    cursor: pointer;
    opacity: 0;
    display: none;
}

#check:checked ~ .hamburger-menu-container .hamburger-menu div {
    background-color: transparent;
}

#check:checked ~ .hamburger-menu-container .hamburger-menu div:before {
    transform: translateY(0) rotate(-45deg);
}

#check:checked ~ .hamburger-menu-container .hamburger-menu div:after {
    transform: translateY(0) rotate(45deg);
}

@keyframes slide-down {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fade-in-up {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes animation {
    from {
        opacity: 0;
        transform: translateY(15px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

@media (max-width: 1000px) {
    .logo {
        margin-left: 0; /* Adjusted for better centering */
    }
}

@media (max-width: 920px) {
    .hamburger-menu-container {
        display: flex;
    }

    #check {
        display: block;
    }

    .nav-btn { 
        position: fixed;
        height: calc(100vh - 3rem);
        top: 3rem;
        left: 0;
        width: 100%;
        background-color: var(--clr-btn);
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        overflow-x: hidden;
        overflow-y: auto;
        transform: translateX(-100%);
        transition: .65s;
    }

    #check:checked ~ .nav-btn {
        transform: translateX(0);
    }

    #check:checked ~ .nav-btn .nav-link,
    #check:checked ~ .nav-btn .log-sign {
        animation: animation .5s ease forwards var(--i);
    }

    .nav-links {
        flex: initial;
        width: 100%;
    }

    .nav-links > ul {
        flex-direction: column;
    }

    .nav-link {
        width: 100%;
        opacity: 0;
        transform: translateY(15px);
    }

    .nav-link > a {
        line-height: 1;
        padding: 1.6rem 2rem;
    }

    .nav-link:hover > a {
        transform: scale(1);
        background-color: var(--clr-nav-hover);
    }

    .dropdown,
    .dropdown.second {
        position: initial;
        top: initial;
        left: initial;
        transform: initial;
        opacity: 1;
        pointer-events: auto;
        width: 100%;
        padding: 0;
        background-color: var(--clr-dropdown-hov);
        display: none;
    }

    .nav-link:hover > .dropdown,
    .dropdown-link:hover > .dropdown {
        display: block;
    }

    .nav-link:hover > a > i,
    .dropdown-link:hover > a > i {
        transform: rotate(360deg);
    }

    .dropdown-link > a {
        background-color: transparent;
        color: var(--clr-light);
        padding: 1.2rem 2rem;
        line-height: 1;
    }

    .dropdown.second .dropdown-link > a {
        padding: 1.2rem 2rem 1.2rem 3rem;
    }

    .dropdown.second .dropdown.second .dropdown-link > a {
        padding: 1.2rem 2rem 1.2rem 4rem;
    }

    .dropdown-link:not(:nth-last-child(2)) {
        border-bottom: none;
    }

    .arrow {
        z-index: 1;
        background-color: var(--clr-btn);
        left: 10%;
        transform: scale(1.1) rotate(45deg);
        transition: .5s;
    }
    .logo-rca{
        width: 22vw;
        height: auto;
        margin-top: -4px;
    }

    .nav-link:hover .arrow {
        background-color: var(--clr-nav-hover);
    }

    .dropdown .dropdown .arrow {
        display: none;
    }

    .dropdown-link:hover > a {
        background-color: var(--clr-dropdown-link-hov);
    }

    .dropdown-link:first-child:hover ~ .arrow {
        background-color: var(--clr-nav-hover);
    }

    .nav-link > a > i {
        font-size: 1.1rem;
        transform: rotate(-90deg);
        transition: .7s;
    }

    .dropdown i {
        font-size: 1rem;
        transition: .7s;
    }

    .log-sign {
        flex: initial;
        width: 100%;
        padding: 1.5rem 1.9rem;
        justify-content: flex-start;
        opacity: 0;
        transform: translateY(15px);
    }
}

@media (max-width: 480px) {
    .logo {
        font-size: 12px; /* Adjust for smaller screens */
        margin-left: 0; /* Center align the logo */
    }

    .btn {
        padding: .4rem 1rem; /* Reduce button padding */
        font-size: .7rem; /* Adjust button font size */
    }

    .nav-link > a {
        font-size: .8rem; /* Reduce nav link font size */
        padding: 1rem 1.5rem; /* Adjust nav link padding */
    }

    .dropdown-link > a {
        font-size: .8rem; /* Reduce dropdown link font size */
        padding: 1rem 1.5rem; /* Adjust dropdown link padding */
    }

    .log-sign {
        padding: 1rem 1.5rem; /* Adjust padding for log-sign */
    }
}
