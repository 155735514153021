* {
    box-sizing: border-box;
}

.center {
    width: 100%;
    /* margin: auto; */
    max-width: 840px;
    padding: 20px;
}

.join {
    font-size: 22px;
    margin-bottom: 2rem;
}
.field{
    
}

.signUp {
    display: flex;
    width: 80vw;
    margin-left: 9vw;
    margin-top: 5vw;
    margin-bottom: 5vw;
    transition: 0.5s ease-in-out;
    border-radius: 5px;

    &.close {
        transform: translate(-25%, 0);
    }

    .left,
    .right {
        width: 50%;
        padding: 80px 20px;
    }

    .left {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        text-align: center;
        background: url("./admission.jpg") center/cover no-repeat;
        color: white;
        transform-origin: center right;
        position: relative;
        transform-style: preserve-3d;
        perspective: 1000px;
        overflow: hidden;

        .back {
            position: absolute;
            top: -50%;
            left: -50%;
            width: 2vw;
            height: 200%;
            background: url("./admission.jpg") center/cover no-repeat;
            transform: rotateY(180deg);
            z-index: -1;
        }

        &.close {
            transform: rotateY(-180deg);
            transition: 1s ease-in-out;

            .back {
                transform: rotateY(0);
                transition: 1s ease-in-out;
            }
        }
    }

    .right {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;
        box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.35);

        form {
            width: 100%;

            input[type='text'],
            input[type='email'],
            input[type='password'] {
                width: 100%;
                display: block;
                margin: 10px 0;
                padding: 10px 0;
                border: none;
                outline: none;
                border-bottom: 1px solid #ccc;

                &.first {
                    margin-top: 0;
                }
            }

            input[type='submit'] {
                padding: 10px 40px;
                border: 2px solid black;
                background: white;
                color: black;
                transition: 0.15s ease-in-out;
                border-radius: 5px;
                outline: none;

                &:hover {
                    background: black;
                    color: white;
                }
            }
        }

        .input-bordered {
            width: 100%;
            position: relative;

            &.active {
                label {
                    top: 0;
                    left: 0;
                    transform: translate(0, -50%) scale(0.75);
                    color: black;
                }

                &:after {
                    width: 100%;
                }
            }

            &:after {
                content: '';
                display: block;
                width: 0;
                height: 1px;
                background: black;
                position: absolute;
                bottom: 0;
                transition: 0.15s ease-in-out;
            }

            label {
                color: #444;
                font-size: 15px;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
                transition: 0.15s ease-in-out;
                cursor: text;
                transform-origin: center left;
            }
        }
    }
}

/* Media Queries */
@media (max-width: 1024px) {
    .signUp {
        width: 90vw;
        margin-left: 5vw;
        margin-top: 4vw;
        margin-bottom: 4vw;

        .left,
        .right {
            padding: 60px 20px;
        }
    }
}

@media (max-width: 768px) {
    .signUp {
        flex-direction: column;

        .left,
        .right {
            width: 100%;
            padding: 40px 20px;
        }
    }

    .field {
        margin-top: 1rem;
    }

    .i-u {
        margin-top: 2rem;
    }


}

@media (max-width: 480px) {
    .signUp {
        width: 90vw;
        margin-left: 0vw;
        margin-top: 3vw;
        margin-bottom: 3vw;

        .left,
        .right {
            padding: 20px 10px;
        }

        .join {
            font-size: 18px;
        }


        .right form {
            input[type='submit'] {
                padding: 10px 30px;
            }
        }
    }
}