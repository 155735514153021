/* Define CSS Variables */
:root {
  --gray-darker: #444444;
  --gray-dark: #696969;
  --gray: #999999;
  --gray-light: #cccccc;
  --gray-lighter: #ececec;
  --gray-lightest: #f2f2f2;
  /* lighten(@gray-lighter, 4%) */
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.users {
  text-align: center;
  font-style: italic;
}




img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

.btn {
  background-color: white;
  border: 1px solid var(--gray-light);
  color: var(--gray-dark);
  padding: 0.5rem;
  text-transform: lowercase;
}

.btn--block {
  display: block;
  width: 100%;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.cards__item {
  display: flex;
  padding: 1rem;
}

@media(min-width: 40rem) {
  .cards__item {
    width: 50%;
  }
}

@media(min-width: 56rem) {
  .cards__item {
    width: 33.3333%;
  }
}

.card {
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.card:hover .card__image {
  filter: contrast(100%);
}

.card__content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 1rem;
}

.card__image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  filter: contrast(70%);
  overflow: hidden;
  position: relative;
  transition: filter 0.5s cubic-bezier(.43, .41, .22, .91);
}

.card__image::before {
  content: "";
  display: block;
  padding-top: 56.25%;
  /* 16:9 aspect ratio */
}

@media(min-width: 40rem) {
  .card__image::before {
    padding-top: 66.6%;
    /* 3:2 aspect ratio */
  }
}

.card__image--flowers {
  background-image: url('./turfimages.jpg');
}

.card__image--river {
  background-image: url('./441056246_416057117849088_8259596243903552348_n.jpg');
}

.card__image--record {
  background-image: url('./436283348_403269992461134_1756325190032020770_n.jpg');
}

.card__image--fence {
  background-image: url(https://swaviman.com/wp-content/uploads/2023/07/Kishor-Mahato-1024x683-1.jpg);
}

.card__image--chandan {
  background-image: url('./selection.jpg');
}
.card__image--npl{
  background-image: url('./npl.jpg');
}

.card__title {
  color: var(--gray-dark);
  font-size: 1.25rem;
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.card__text {
  flex: 1 1 auto;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 1.25rem;
}

@media(max-width:768px) {
  .card {
    margin-left: 0rem;
    width: auto;
  }

}