#app { height: 100% }




.swiper {
  width: auto;
  height: auto;
  position: relative;
  background-position: top center;
  background-attachment: fixed;
  background: #000;
}

.swiper-slide {
  font-size: 18px;
  color: #fff;
  margin-top: 8rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 60px;
  
}
.slidertext{
  color: white;
}


.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;

  width: 130%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

.swiper-slide .title {
  font-size: 41px;
  font-weight: 300;
  color: rgb(255, 255, 255);
  font-weight: bold;
}

.swiper-slide .subtitle {
  font-size: 21px;
  color: rgb(255, 255, 255);
  font-weight: bold;
}

.swiper-slide .text {
  font-size: 20px;
  width:50vw;
  color: rgb(255, 255, 255);
  margin-top: 3rem;

  font-weight:bold;
  line-height: 1.3;
}

@media (max-width:768px){
  .swiper-slide .text {
    font-size: 20px;
    width:80vw;
    color: rgb(255, 255, 255);
 
    margin-top: 3rem;
  
    font-weight:bold;
    line-height: 1.3;
  }


  .swiper {
    width: 100%;
    height: auto;
    background: #000;
  }

}
