/* General styles */


.link{
    color: #33383b;
  }
  
  .login_form {
    max-width: 40vw;
    margin: 0 auto;
    padding: 20px;
    margin-top: 12rem;
    margin-bottom: 7rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .login_form div {
    margin-bottom: 15px;
  }
  
  .login_form label {
    display: block;
    margin-bottom: 5px;
    font-size: 1rem;
    color: #333;
  }
  
  .login_form input {
    width: 37vw;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    box-sizing: border-box;
  }
  
  .login_form button {
    width: 100%;
    padding: 10px;
    background-color: #33383b;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .login_form button:hover {
    background-color: #33383b;
  }
  
  .login_form .error {
    color: red;
    margin-bottom: 15px;
  }
  
  .login_form span {
    display: block;
    text-align: center;
    margin-top: 10px;
  }
  
  .login_form span button {
    background-color: transparent;
    border: none;
    color: #33383b;
    cursor: pointer;
    text-decoration: underline;
    font-size: 1rem;
  }
  
  .login_form span button:hover {
    color: #ffffff;
  }
  
  
  form input[type="text"], form textarea {
    width: calc(50% px);
    flex: 1 1 45%;
  }
  
  @media (max-width: 768px) {
    .login_form {
    
      border: none;
      box-shadow: none;
      border-radius: 0;
      max-width: 110vw;
    }
    .login_form input {
      width: 70vw;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 1rem;
      box-sizing: border-box;
    }
  
    .login_form input,
    .login_form button,
    .login_form span button {
      font-size: 0.9rem;
    }
  
    .login_form label {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .login_form {
  
      width: 90vw;
    }
  
    .login_form input,
    .login_form button,
    .login_form span button {
      font-size: 0.8rem;
    }
  
    .login_form label {
      font-size: 0.8rem;
    }
  }