/* CallButton.css */
.call-button {
    position: fixed;
    left: 20px;
    bottom: 20px;
    background-color: #ffffff; /* Green color */
    color: rgb(0, 0, 0);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: background-color 0.3s;
  }
  #phone{
    color: rgb(0, 0, 0);
  }
  
  .call-button:hover {
    background-color: #218838; /* Darker green */
  }
  